<template>

  <!-- Table Application Changelog Card -->
  <b-card
    no-body
  >
    <div class="table-header m-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center">
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refEventLogsListTable"
      :items="fetchEventLogsList"
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :busy="isLoading"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      :sort-by.sync="sortBy"
      class="position-relative"
    >
      <!-- Loader -->
      <template #table-busy>
        <TableSpinner />
      </template>

      <template #head(show_details)>
        <div class="d-flex">
          <span>#</span>
        </div>
      </template>

      <!-- Column: show_details -->
      <template #cell(show_details)="data">
        <feather-icon
          :icon="data.item._showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="16"
          class="align-middle text-body"
          @click="data.toggleDetails"
        />
      </template>

      <template #row-details="data">
        <event-log-details-table
          :items="data.item.properties"
        />
      </template>

      <!-- Column: Created At -->
      <template #cell(created_at)="data">
        <span :id="data.item.id">{{ data.item.created_at | longDateTime }}</span>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-if="totalItems && perPage !== 'all'"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { filters } from '@core/mixins/filters'
import {
  BCard,
  BCol,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import EventLogDetailsTable from '@/views/components/application-events-log/EventLogDetailsTable.vue'
import { appEventLogsList } from '@/store/modules/app-event-logs-list'

export default Vue.extend({
  components: {
    BPagination,
    BCol,
    BRow,
    BTable,
    BCard,
    vSelect,
    EventLogDetailsTable,
    TableSpinner,
  },
  mixins: [filters],
  data() {
    return {
      isLoading: false,
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      applicationId: 'app-application-edit/getApplicationId',
    }),
    tableColumns() {
      return appEventLogsList.getters.getTableColumns
    },
    perPage() {
      return appEventLogsList.getters.getPerPage
    },
    totalItems() {
      return appEventLogsList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appEventLogsList.getters.getCurrentPage
      },
      set(val) {
        appEventLogsList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appEventLogsList.getters.getPerPageOptions
    },
    sortBy: {
      get() {
        return appEventLogsList.getters.getSortBy
      },
      set(val) {
        appEventLogsList.mutations.SET_SORT_BY(val)
      },
    },
    isSortDirDesc: {
      get() {
        return appEventLogsList.getters.getIsSortDirDesc
      },
      set(val) {
        appEventLogsList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    dataMeta() {
      return appEventLogsList.getters.getDataMeta
    },
  },
  watch: {
    perPage(val, prevVal) {
      if (val !== prevVal) {
        this.updateTable()
      }
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        this.updateTable()
      }
    },
    sortBy(val, prevVal) {
      if (val !== prevVal) {
        this.updateTable()
      }
    },
    isSortDirDesc(val, prevVal) {
      if (val !== prevVal) {
        this.updateTable()
      }
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  created() {
    appEventLogsList.mutations.SET_APPLICATION_ID(this.applicationId)
  },
  methods: {
    setFilterParams() {
      appEventLogsList.mutations.SET_FILTER_PARAMS()
    },
    updatePerPage(val) {
      appEventLogsList.mutations.SET_PER_PAGE(val)
    },
    async fetchEventLogsList() {
      return appEventLogsList.actions.fetchEventLogsList()
    },
    updateTable() {
      (this.$refs.refEventLogsListTable).refresh()
    },
  },
})
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .per-page-selector {
    width: 90px;
  }

</style>
