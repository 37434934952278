<template>
  <b-row>
    <b-col
      cols="6"
      md="6"
    >
      <b-card
        md="6"
      >
        <b-card-header>
          <h3>Old Data</h3>
        </b-card-header>

        <b-card-body>
          <div
            v-for="(value, propertyName) in items.old"
            :key="propertyName"
          >
            <ul>
              <li>
                {{ propertyName }} :
                <ul v-if="Array.isArray(value)">
                  <li
                    v-for="(item, index) in value"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
                <p
                  v-else
                  class="ml-1"
                >
                  {{ value }}
                </p>
              </li>
            </ul>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col
      cols="6"
      md="6"
    >
      <b-card>
        <b-card-header>
          <h3>New Data</h3>
        </b-card-header>

        <b-card-body>
          <div
            v-for="(value, propertyName) in items.attributes"
            :key="propertyName"
          >
            <ul>
              <li>
                {{ propertyName }} :
                <ul v-if="Array.isArray(value)">
                  <li
                    v-for="(item, index) in value"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
                <p
                  v-else
                  class="ml-1"
                >
                  {{ value }}
                </p>
              </li>
            </ul>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCardHeader,
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import { filters } from '@core/mixins/filters'
import { transactionsMixin } from '@/views/admin/transactions-v2/mixins/transactionsMixin'

export default {
  name: 'EventLogDetailsTable',
  components: {
    BCardBody,
    BCard,
    BCardHeader,
    BRow,
    BCol,
  },
  mixins: [filters, transactionsMixin],
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: { },
}
</script>

<style>

</style>
