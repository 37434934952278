import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { EventLogsListState, IEventLogsListRequest } from './types'
import { fetchEventLogsList } from '@/api/eventLogs'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appEventLogsList = createModule(
  'appEventLogsList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'show_details', label: '#' },
        {
          key: 'log_name', label: 'Type', sortable: true,
        },
        {
          key: 'event', label: 'Event', sortable: true,
        },
        {
          key: 'causer_user_role', label: 'Causer User Role', sortable: true,
        },
        {
          key: 'causer_user_name', label: 'Causer User Name', sortable: true,
        },
        {
          key: 'description', label: 'Description', sortable: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true,
        },
      ],
      perPage: Number(localStorage.getItem('eventLogsPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      applicationId: '' as string | (string | null)[],
    } as EventLogsListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
      getApplicationId: state => state.applicationId,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('eventLogsPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('eventLogsPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
      },
      SET_APPLICATION_ID(state, val) {
        state.applicationId = val
      },
    },
    actions: {
      async fetchEventLogsList({ state }) {
        try {
          const queryParams: IEventLogsListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
          }
          if (state.searchQuery) queryParams.name = state.searchQuery
          if (state.applicationId) queryParams.applicationId = state.applicationId

          if (state.sortBy === 'causer_user_name') queryParams.sortBy = 'causer_type'
          if (state.sortBy === 'causer_user_role') queryParams.sortBy = 'causer_id'

          const response:any = await fetchEventLogsList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appEventLogsList.mutations.SET_TOTAL_ITEMS(total)
          appEventLogsList.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching logs list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
    },
  },
)

appEventLogsList.register(store)
