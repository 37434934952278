import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    applicationId: null,
    applicationData: null,
    isLoading: true,
    type: null,
    stepsList: [],
    camper: null,
    userInfo: {
      extra_user_info: {},
    },
    applicationCreatedAt: '',
    applicationGrade: '',
    applicationStatus: '',
    applicationStatusName: '',
    isApplicationAccepted: false,
    isLockedStatus: false,
    newApplicationStatus: null,
    newApplicationStepStatus: null,
    groupsList: [],
    removedGroupsList: [],
    autoAssignedGroupsList: [],
    sessionsList: null,
    statusesList: [],
    statusesLoadingStatus: 'Loading...',
    updatedStep: null,
    programId: '',
    programName: '',
    paymentPlanExists: false,
    cardId: null,
    paymentContract: null,
    paymentOption: null,
    applicationPaymentOption: null,
    applicationBalance: 0,
    confirmationChangeStatusModalHide: false,
    newSelectedStatus: '',
    isSaveButtonDisabled: false,
    tagNames: [],
    limitSessionSelection: false,
    applicationLastStepName: '',
  },
  getters: {
    getApplicationId: state => state.applicationId,
    getApplicationData: state => state.applicationData,
    getIsLoading: state => state.isLoading,
    getType: state => state.type,
    getStepsList: state => state.stepsList,
    getCamper: state => state.camper,
    getUserInfo: state => state.userInfo,
    getUserId: state => state.userInfo.id,
    getApplicationCreatedAt: state => state.applicationCreatedAt,
    getApplicationGrade: state => state.applicationGrade,
    getApplicationStatus: state => state.applicationStatus,
    getIsLockedStatus: state => state.isLockedStatus,
    getApplicationStatusName: state => state.applicationStatusName,
    getIsApplicationAccepted: state => state.isApplicationAccepted,
    getNewApplicationStatus: state => state.newApplicationStatus,
    getApplicationLastStepName: state => state.applicationLastStepName,
    getGroupsList: state => state.groupsList,
    getRemovedGroupsList: state => state.removedGroupsList,
    getAutoAssignedGroupsList: state => state.autoAssignedGroupsList,
    getSessionsList: state => state.sessionsList,
    getStatusesList: state => state.statusesList,
    getStatusesLoadingStatus: state => state.statusesLoadingStatus,
    getProgramId: state => state.programId,
    getProgramName: state => state.programName,
    getPaymentPlanExists: state => state.paymentPlanExists,
    getCardId: state => state.cardId,
    getPaymentContract: state => state.paymentContract,
    getPaymentOption: state => state.paymentOption,
    getApplicationPaymentOption: state => state.applicationPaymentOption,
    getApplicationBalance: state => state.applicationBalance,
    getConfirmationChangeStatusModalHide: state => state.confirmationChangeStatusModalHide,
    getNewSelectedStatus: state => state.newSelectedStatus,
    getIsSaveButtonDisabled: state => state.isSaveButtonDisabled,
    getNewApplicationStepStatus: state => state.newApplicationStepStatus,
    getUpdatedStep: state => state.updatedStep,
    getTagNames: state => state.tagNames,
    getLimitSessionSelection: state => state.limitSessionSelection,
  },
  mutations: {
    SET_APPLICATION_ID: (state, val) => {
      state.applicationId = val
    },
    SET_TYPE: (state, val) => {
      state.type = val
    },
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_STATUSES_LIST: (state, val) => {
      state.statusesList = val
    },
    SET_STATUSES_LOADING_STATUS: (state, val) => {
      state.statusesLoadingStatus = val
    },
    SET_CONFIRMATION_CHANGE_STATUS: (state, val) => {
      state.confirmationChangeStatusModalHide = val
    },
    SET_NEW_SELECTED_STATUS: (state, val) => {
      state.newSelectedStatus = val
    },
    SET_IS_SAVE_BUTTON_DISABLED: (state, val) => {
      state.isSaveButtonDisabled = val
    },
    SET_NEW_APPLICATION_STEP_STATUS: (state, val) => {
      state.newApplicationStepStatus = val
    },
    SET_UPDATED_STEP: (state, val) => {
      state.updatedStep = val
    },
    SET_TAG_NAMES: (state, val) => {
      state.tagNames = val
    },
    ADD_TAG_NAME: (state, val) => {
      state.tagNames.push(val)
    },
    DELETE_STEP: (state, payload) => {
      const statusIndex = state.statusesList.findIndex(item => item.id === payload.statusId)
      const stepIndex = state.statusesList[statusIndex].steps.findIndex(item => item.id === payload.stepId)
      state.statusesList[statusIndex].steps.splice(stepIndex, 1)
    },
    REMOVE_TAG_NAME: (state, val) => {
      const index = state.tagNames.findIndex(item => item.id === val.id)
      state.tagNames.splice(index, 1)
    },
    SET_APPLICATION_DATA(state, response) {
      state.applicationData = response
      state.applicationStatus = response.status
      state.applicationStatusName = response.status?.name
      state.newApplicationStatus = response.status?.id
      state.isLockedStatus = response.is_locked_status
      state.isApplicationAccepted = response.is_accepted
      state.camper = response.camper
      state.cardId = response.card_id
      state.userInfo = response.user
      state.stepsList = response.steps.map(step => ({
        ...step,
        results: response.application_steps_results.find(result => result.step_id === step.id),
      }))
      state.applicationCreatedAt = response.created_at
      state.applicationGrade = response.grade
      state.groupsList = response.groups
      state.removedGroupsList = response.removed_groups
      state.sessionsList = response.sessions
      state.programId = response.program_id
      state.programName = response.program
      state.paymentPlanExists = response.payment_plan_exists
      state.paymentContract = response.payment_contract
      state.paymentOption = response.payment_option
      state.applicationPaymentOption = response.application_payment_option
      state.applicationBalance = response.balance
      state.tagNames = response.tags
      state.limitSessionSelection = response.is_only_one_session_selection_allowed
      state.applicationLastStepName = response.last_step_name
      state.isLoading = false
    },
    SET_AUTO_ASSIGNED_GROUPS: (state, val) => {
      state.autoAssignedGroupsList = val
    },
  },
  actions: {
    fetchApplicationList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/applications', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProgramList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/programs', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTagSetsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/tag-sets', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchApplicationsByStatuses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/applications-by-statuses', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchApplication({ state, commit }) {
      try {
        const response = await axios.get(`/auth/applications/${state.applicationId}/edit`)

        commit('SET_APPLICATION_DATA', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchApplicationStatuses({ state, commit }) {
      try {
        const response = await axios.get('/auth/registration-form-statuses', {
          params: { application_id: state.applicationId },
        })

        commit('SET_STATUSES_LIST', response.data.data)
        commit('SET_STATUSES_LOADING_STATUS', 'Select Status')
        return response
      } catch (error) {
        return error
      }
    },
    async updApplicationStatus({ state, commit, dispatch }, { status, withoutCharging, vm }) {
      try {
        commit('SET_IS_SAVE_BUTTON_DISABLED', true)

        const queryParams = {
          status_id: status,
          without_charging: withoutCharging,
        }

        await axios.post(`/auth/application-status/${state.applicationId}`, queryParams)

        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Application Status was successfully changed',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })

        await dispatch('fetchApplication')
        commit('SET_IS_SAVE_BUTTON_DISABLED', false)
        vm.$bvModal.hide('change-status')
      } catch (error) {
        commit('SET_IS_SAVE_BUTTON_DISABLED', false)
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: `Error change Application Status: ${error.response.data.message}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async updApplicationTags({ state, commit, dispatch }, { tags, vm }) {
      try {
        const queryParams = {
          tag_ids: tags,
        }
        commit('SET_IS_SAVE_BUTTON_DISABLED', true)

        await axios.post(`/auth/applications/${state.applicationId}/tags`, queryParams)

        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Application Tags was successfully changed',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })

        commit('SET_IS_SAVE_BUTTON_DISABLED', false)
        vm.$bvModal.hide('change-status')

        await dispatch('fetchApplication')
      } catch (error) {
        commit('SET_IS_SAVE_BUTTON_DISABLED', false)
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error change Application Tags',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async fetchApplicationAutoAssignedGroups({ state, commit }) {
      try {
        const response = await axios.get(`auth/applications/${state.applicationId}/auto-assigned-group`)

        commit('SET_AUTO_ASSIGNED_GROUPS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async updApplicationLockedStatus({ state, commit, dispatch }, { vm }) {
      try {
        commit('SET_IS_SAVE_BUTTON_DISABLED', true)

        await axios.post(`/auth/applications/${state.applicationId}/update-locked-status`)

        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Locking Status of Updating Application Statuses was successfully changed',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })

        state.isLockedStatus = !state.isLockedStatus
        commit('SET_IS_SAVE_BUTTON_DISABLED', false)

        await dispatch('fetchApplication')
      } catch (error) {
        commit('SET_IS_SAVE_BUTTON_DISABLED', false)
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error change Locking Status of Updating Application Statuses',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
