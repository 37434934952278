<template>
  <app-collapse class="mb-2">
    <app-collapse-item
      title
      :is-visible="isVisible"
    >
      <template #header>
        <h4
          class="d-flex align-items-center mb-0"
        >
          <feather-icon
            icon="ListIcon"
            size="18"
            class="mr-1"
          />
          Notes
        </h4>
      </template>
      <b-card no-body>
        <div
          v-for="comment in commentsList"
          :key="comment.id"
          class="d-flex justify-content-between align-items-start"
        >
          <div class="d-flex justify-content-between align-items-start flex-wrap">
            <b-avatar
              v-if="comment.user"
              size="48"
              :src="comment.user.avatar"
              :text="avatarText(comment.user.full_name)"
              :variant="`light-${resolveUserRoleVariant(comment.user.role)}`"
              class="mr-75"
            />
            <b-avatar
              v-else
              size="48"
              :variant="`light-primary`"
              class="mr-75 mb-2"
            />
            <div>
              <h5 class="mb-0">
                {{ comment.user ? comment.user.full_name : '' }}
              </h5>
              <span class="font-small-3 text-muted">{{ comment.user ? comment.user.email : '' }}</span>
              <div><p>{{ comment.data }}</p></div>
            </div>
          </div>
          <small class="font-small-3 text-muted text-nowrap">{{ formatDate(comment.created_at) }}</small>
        </div>

        <validation-observer
          ref="noteRules"
          tag="form"
        >
          <b-form
            v-if="showAddComment"
            class="mt-2"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="text"
                rules="required"
              >
                <b-form-textarea
                  v-model="newComment"
                  rows="4"
                  placeholder="Add Comment"
                  class="mb-2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              @click="addComment"
            >Post Comment
            </b-button>

            <div
              v-if="validationErrors.length"
              class="form-group pt-1"
            >
              <p
                v-for="(validationError, index) in validationErrors"
                :key="index"
                class="text-center text-danger"
              >
                {{ validationError }}
              </p>
            </div>
          </b-form>
        </validation-observer>

      </b-card>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import {
  BCard, BAvatar,
  BForm, BFormTextarea, BButton, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { avatarText, formatDate } from '@core/utils/filter'
import useUsersList from '@/views/apps/user/users-list/useUsersList'
import useUserNotes from '@/views/components/user-notes/useUserNotes'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BAvatar,
    BForm,
    BFormTextarea,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    applicationId: {
      type: String,
      required: false,
      default: '',
    },
    showAddComment: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      commentsList: [],
      newComment: '',
      validationErrors: [],
      required,
    }
  },
  setup() {
    const {
      postComment,
      fetchComments,
    } = useUserNotes()

    const { resolveUserRoleVariant } = useUsersList()

    return {
      postComment,
      fetchComments,
      avatarText,
      formatDate,

      resolveUserRoleVariant,
    }
  },
  async created() {
    await this.setComments()
  },
  methods: {
    async setComments() {
      const queryParams = {
        application_id: this.applicationId,
      }
      await this.fetchComments(queryParams).then(response => {
        this.commentsList = response
      })
    },
    async addComment() {
      this.validationErrors = []
      const queryParams = {
        application_id: this.applicationId,
        data: this.newComment,
      }

      return new Promise(() => {
        this.$refs.noteRules.validate().then(success => {
          if (success) {
            this.postComment(queryParams).then(response => {
              if (response.status === 422) {
                const validationErrors = Object.values(response.data.errors)
                this.validationErrors = validationErrors.flat()
              } else {
                this.newComment = ''
                this.setComments()
              }
            })
          }
        })
      })
    },
  },
}
</script>

<style>

</style>
